import React, { useEffect, useMemo, useState } from "react"
import { Box, SimpleGrid, Text } from "@chakra-ui/react"

const countDownDate = new Date("2021-07-25T18:00:00Z").getTime()
export const Countdown = () => {
  const [date, setDate] = useState(() => new Date().getTime())
  useEffect(() => {
    const x = setInterval(() => {
      setDate(new Date().getTime())
    }, 1000)
    return () => clearInterval(x)
  }, [])

  const times = useMemo(() => {
    var distance = countDownDate - date;
    return {
      days: Math.floor(distance / (1000 * 60 * 60 * 24)),
      hours: Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
      minutes: Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)),
      seconds: Math.floor((distance % (1000 * 60)) / 1000)
    }
  }, [date])
  return (
    <Box>
      <SimpleGrid templateRows="2fr 1fr" spacing={1} lineHeight="1" autoFlow={{base: 'row', sm: 'column' }}>
        <Text fontWeight="800" fontSize="5xl">{times.days}</Text>
        <Text>days</Text>
        <Text fontWeight="800" fontSize="5xl">{times.hours}</Text>
        <Text>hours</Text>
        <Text fontWeight="800" fontSize="5xl">{times.minutes}</Text>
        <Text>minutes</Text>
        <Text fontWeight="800" fontSize="5xl" color="red.500">{times.seconds}</Text>
        <Text color="red.500">seconds</Text>
      </SimpleGrid>
    </Box>
  )
}