import React from "react"
import Seo from "../components/seo"
import { Box, Flex, Heading, Link, Text } from "@chakra-ui/react"
import { Countdown } from "../components/Countdown"
import { StaticImage } from "gatsby-plugin-image"
import { LIVESTREAM_URL } from "../utils/constants"
import { Link as GatsbyLink } from "gatsby"

const weddingStarted = true
const IndexPage = () => {
  return (
    <>
      <Seo />
      <Flex
        direction={{ base: "column", xl: "row" }}
        bg="blackAlpha.900"
        p="30px"
        alignItems={{ base: "initial", xl: "center" }}
        justifyContent="space-around"
        minH="100vh"
      >
        <Box color="whiteAlpha.900" textAlign="center" px={{base: 1, md: 5}} minW={{md: '450px'}} maxW={{md: '500px'}} mb={{ base: 10, xl: 0 }} alignSelf="center">
          <Heading fontSize={{ base: 80, md: 80 }} lineHeight="1">
            Tammie & Yoni
          </Heading>
          <Heading fontSize={{ base: 80, md: 60 }} lineHeight="1">
            7.25.2021
          </Heading>
          <Box mt={10} mb={10}>
            <StaticImage
              src="../images/divider.png"
              alt="divider"
              // width="100%"
              // height="100px"
              objectFit="contain"
              loading="eager"
              placeholder="none"
              layout="fullWidth"
            />
          </Box>
          <Box mb={10}>
            <Link
              fontSize="2xl"
              textDecor="underline"
              _hover={{ color: "whiteAlpha.700" }}
              href="https://www.theknot.com/us/tammie-senders-and-yoni-bryk-jul-2021/registry"
              target="_blank"
              rel="noopener noreferrer"
            >
              Our Wedding Registry
            </Link>
          </Box>
          {/* <Box mb={10}>
            <Button
              as={Link}
              fontSize="2xl"
              textDecor="underline"
              _hover={{ color: "whiteAlpha.700" }}
              fontWeight="normal"
              bgColor="inherit"
              onClick={() => {}}
            >
              Sign our Guest Book!
            </Button>
          </Box> */}
          {weddingStarted ? (
            <Box mb={{base: 5, md: 0}}>
              <Text fontSize="xl" mb={3}>Use the button on the player to go full screen, or head here for the full page view:<br />
              <Link as={GatsbyLink} to="/live" color="teal.500">https://tammieyoni.com/live</Link>
            </Text>
            </Box>
          ) : (
            <Box mb={{base: 5, md: 0}}>
              <Text fontSize="xl" mb={3}>The Live Stream Will Begin In:</Text>
              <Countdown />
            </Box>
          )}
        </Box>
        <Box flexGrow="1" pos="relative">
          <Box pt="56.25%" pos="relative">
            <iframe
              title="Vimeo Livestream"
              src={LIVESTREAM_URL}
              frameBorder="0"
              allow="autoplay; fullscreen; picture-in-picture"
              allowFullScreen
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%'
              }}
            />
          </Box>
        </Box>
      </Flex>
    </>
  )
}

export default IndexPage
